import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import {
  LambdaFunctionFilter,
  LambdaFunctionSortEnum,
} from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { GET_RESOURCES_FAILURE, LAMBDA_LABEL } from "../constants";

export interface LambdaFunctionQueryParams {
  accounts: string[];
  runtimes: string[];
  handlers: string[];
  sort: LambdaFunctionSortEnum;
  search: string;
}

export const GET_LAMBDA_FUNCTIONS = gql(/* GraphQL */ `
  query LambdaFunctions(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $searchTerm: String
    $sort: [LambdaFunctionSortEnum]
    $where: LambdaFunctionFilter
  ) {
    lambdaFunctions(
      first: $first
      after: $after
      last: $last
      before: $before
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          accountId
          accountName
          codeSize
          handler
          memorySize: memory
          functionName: name
          region
          runtime
          tags
        }
      }
    }
  }
`);

export const GET_LAMBDA_FUNCTIONS_CSV = gql(/* GraphQL */ `
  query LambdaFunctionsCsv(
    $searchTerm: String
    $sort: [LambdaFunctionSortEnum]
    $where: LambdaFunctionFilter
  ) {
    lambdaFunctions(searchTerm: $searchTerm, sort: $sort, where: $where) @csv {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          FunctionName: name
          AccountName: accountName
          AccountID: accountId
          Runtime: runtime
          Handler: handler
          MemorySize: memory
          Region: region
          CodeSize: codeSize
          Tags: tags
        }
      }
    }
  }
`);

export const useLambdaFunctions = (queryParams: LambdaFunctionQueryParams) => {
  const onError = () => toast.error(GET_RESOURCES_FAILURE(LAMBDA_LABEL));

  const { data, loading, error, fetchMore, refetch } = useQuery(
    GET_LAMBDA_FUNCTIONS,
    {
      variables: getLambdaFunctionsVariables(queryParams),
      onError,
      notifyOnNetworkStatusChange: true,
    }
  );

  const { edges, pageInfo, totalCount } = data?.lambdaFunctions || {};
  const lambdaFunctions = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { lambdaFunctions },
    error,
    loading,
    pagination,
    refetch,
  };
};

export const getLambdaFunctionsVariables = (
  queryParams: LambdaFunctionQueryParams
) => {
  const where: LambdaFunctionFilter = {};

  if (queryParams.accounts) where.accountIn = queryParams.accounts;
  if (queryParams.runtimes) where.runtimeIn = queryParams.runtimes;
  if (queryParams.handlers) where.handlerIn = queryParams.handlers;
  return {
    sort: queryParams.sort,
    ...INITIAL_PAGINATION,
    searchTerm: queryParams.search,
    where,
  };
};
