import { Outlet } from "react-router-dom";

import { MissionControlLogin } from "@mc/app/components";
import { FullScreenLayout } from "@mc/layouts";
import { AuthenticatedProviders } from "@mc/providers";

import { authenticatedRoutes } from "./authenticatedRoutes";
import { SsoOutlet } from "./outlets";
import { RootPathRedirect } from "./redirects";
import { signupRoutes } from "./signupRoutes";

export const routes = [
  {
    index: true,
    element: <RootPathRedirect />,
  },
  {
    path: ":companyId",
    element: (
      <AuthenticatedProviders>
        <Outlet />
      </AuthenticatedProviders>
    ),
    children: authenticatedRoutes,
  },
  {
    path: "signup",
    element: (
      <FullScreenLayout>
        <Outlet />
      </FullScreenLayout>
    ),
    children: signupRoutes,
  },
  {
    path: "sso/:domain/*",
    element: <SsoOutlet />,
    children: [{ path: "*", element: <MissionControlLogin /> }],
  },
];
