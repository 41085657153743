import { useMemo } from "react";

import { MarketplaceDimension, Product } from "@m/api/public/types";
import { FilterBar, FilterSelect } from "@m/ui";

type QueryParamsType = {
  startDate: Date;
  endDate: Date;
  productCode: string;
  dimensionCode: string;
};
export const MeteredChargesTableFilter = ({
  query,
  eligibleProducts,
  dimensions,
  handleQueryChange,
  handleResetFilters,
  loading,
}: {
  query: QueryParamsType;
  eligibleProducts: Pick<Product, "productCode" | "displayName">[];
  dimensions: Pick<MarketplaceDimension, "displayName" | "dimensionTypeId">[];
  handleQueryChange: ({ productCode, dimensionCode }: QueryParamsType) => void;
  handleResetFilters: () => void;
  loading: boolean;
}) => {
  const [dimensionOptions, productOptions, dimensionLabelMap] = useMemo(() => {
    const dimensionOptions = dimensions.map((dimension) => ({
      id: dimension.dimensionTypeId,
      label: dimension.displayName,
    }));

    const productOptions = eligibleProducts.map((product) => ({
      id: product.productCode,
      label: product.displayName,
    }));

    const labelMap = new Map(
      [...dimensionOptions, ...productOptions].map(
        ({ id, label }: { id: string; label: string }) => [id, label]
      )
    );

    return [dimensionOptions, productOptions, labelMap];
  }, [dimensions, eligibleProducts]);

  const handleSelectProductFilter = (productCode: string | null) => {
    handleQueryChange({ ...query, productCode });
  };

  const handleSelectDimensionsFilter = (dimensionCode: string | null) => {
    handleQueryChange({ ...query, dimensionCode });
  };

  const {
    productCode: selectedProductCode,
    dimensionCode: selectedDimensionCode,
  } = query;

  return (
    <div className="mb-2 flex w-full flex-wrap items-center justify-between">
      <FilterBar
        isActive={true}
        onResetFilters={handleResetFilters}
        className="mb-1.5"
      >
        <FilterSelect
          ariaLabel="Products Filter"
          disabled={loading}
          initialValue="All Products"
          displayValue={
            dimensionLabelMap.get(selectedProductCode) ?? "All Products"
          }
          onChange={handleSelectProductFilter}
          onClear={() => {
            handleSelectProductFilter(null);
          }}
          options={productOptions}
          selection={selectedProductCode ?? "All Products"}
        />
        <FilterSelect
          ariaLabel="Dimensions Filter"
          disabled={loading}
          displayValue={
            dimensionLabelMap.get(selectedDimensionCode) ?? "All Dimensions"
          }
          initialValue="All Dimensions"
          onChange={handleSelectDimensionsFilter}
          onClear={() => {
            handleSelectDimensionsFilter(null);
          }}
          options={dimensionOptions}
          selection={selectedDimensionCode ?? "All Dimensions"}
        />
      </FilterBar>
    </div>
  );
};
