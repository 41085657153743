import { useMemo, useState } from "react";
import {
  ArrayParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { RedshiftClusterSortEnum } from "@m/api/public/types";
import {
  FilterBar,
  FilterSelect,
  Search,
  createFilterOptions,
  useSearchTerm,
} from "@m/ui";
import { formatFullDate } from "@m/utils";

import { Page } from "@mc/components/Page";

import {
  GET_REDSHIFT_CLUSTERS_CSV,
  getRedshiftClustersVariables,
  useRedshiftClusterFilters,
  useRedshiftClusterSearchTerms,
  useRedshiftClusters,
} from "../api";
import {
  ResourceDetails,
  ResourceTagsBadge,
  ResourcesPageHeader,
  ResourcesTable,
} from "../components";
import {
  GET_RESOURCES_EMPTY,
  MISSION_MANAGED_FILTER_ICON,
  MISSION_MANAGED_FILTER_LABEL,
  MISSION_MANAGED_FILTER_OPTIONS,
  REDSHIFT_LABEL,
  TABLE_HEADERS_REDSHIFT_CLUSTERS,
} from "../constants";
import { createAccountOptions } from "../utils";

export const RedshiftClustersPage = () => {
  const [query, setQuery] = useQueryParams({
    missionManaged: StringParam,
    accounts: ArrayParam,
    statuses: ArrayParam,
    nodeTypes: ArrayParam,
    search: StringParam,
    sort: withDefault(
      createEnumParam([...Object.values(RedshiftClusterSortEnum)]),
      RedshiftClusterSortEnum.CreateTimeDesc
    ),
  });

  const {
    data: { searchTerms },
    refetch: refetchSearchTerms,
  } = useRedshiftClusterSearchTerms(query.search);

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ query, setQuery, refetchSearchTerms });

  const {
    data: {
      filters: { accounts, statuses, nodeTypes },
    },
  } = useRedshiftClusterFilters();

  /** keeping the query for the resource last simplifies testing with calls.lastQuery */
  const {
    data: { clusters },
    loading,
    pagination,
  } = useRedshiftClusters(query);

  const [drawerDetails, setDrawerDetails] = useState<ResourceDetails | null>(
    null
  );
  const handleCloseDrawer = () => setDrawerDetails(null);

  const handleSortChange = (sort: RedshiftClusterSortEnum) => {
    pagination.setCurrentPage(1);

    setQuery({ sort });
  };

  const rows = useMemo(
    () =>
      clusters.map((cluster) => {
        const {
          tags = "{}",
          clusterName,
          tagTotal,
          createTime,
        } = cluster || {};
        const parsedTags = JSON.parse(tags);

        return {
          ...cluster,
          tags: (
            <ResourceTagsBadge
              count={tagTotal}
              onClick={setDrawerDetails}
              details={{ name: clusterName, tags: parsedTags }}
            />
          ),
          createTime: formatFullDate(createTime),
        };
      }),
    [clusters]
  );

  const handleClearFilters = () => {
    clearSearchTerm();

    setQuery({
      missionManaged: undefined,
      accounts: undefined,
      statuses: undefined,
      nodeTypes: undefined,
      search: undefined,
    });
  };

  const handleSelectManaged = (missionManaged: string) => {
    if (missionManaged === query.missionManaged) {
      setQuery({
        missionManaged: undefined,
      });
    } else {
      setQuery({
        missionManaged: missionManaged,
      });
    }
  };

  const handleDeselectManagedFilter = () => {
    setQuery({
      missionManaged: undefined,
    });
  };

  const handleSelectedAccountNames = (accountIds: string[]) => {
    setQuery({ accounts: accountIds });
  };

  const handleDeselectAccountsFilter = () => {
    setQuery({ accounts: undefined });
  };

  const handleSelectedStatus = (statuses: string[]) => {
    setQuery({ statuses });
  };

  const handleDeselectStatusFilter = () => {
    setQuery({ statuses: undefined });
  };

  const handleSelectedNodeTypes = (nodeTypes: string[]) => {
    setQuery({ nodeTypes });
  };

  const handleDeselectNodeTypesFilter = () => {
    setQuery({ nodeTypes: undefined });
  };

  const hasActiveFilters =
    !!query.missionManaged ||
    query.accounts?.length > 0 ||
    query.statuses?.length > 0 ||
    query.nodeTypes?.length > 0 ||
    !!searchTerm;

  return (
    <Page
      width="full"
      title={
        <ResourcesPageHeader
          title={REDSHIFT_LABEL}
          exportQuery={GET_REDSHIFT_CLUSTERS_CSV}
          exportVariables={getRedshiftClustersVariables(query)}
        />
      }
    >
      <FilterBar
        isActive={hasActiveFilters}
        onResetFilters={handleClearFilters}
        className="mb-2 p-1"
      >
        <Search
          ariaLabel="Redshift Cluster Search Input"
          disabled={loading}
          dropdownOptions={searchTerms}
          handleSearchInputChange={handleSearchInputChange}
          handleSubmitSearch={handleSubmitSearch}
          searchTerm={searchTerm}
        />
        <FilterSelect
          ariaLabel={MISSION_MANAGED_FILTER_LABEL}
          disabled={loading}
          icon={MISSION_MANAGED_FILTER_ICON}
          initialValue={MISSION_MANAGED_FILTER_OPTIONS.MANAGED}
          onChange={handleSelectManaged}
          onClear={handleDeselectManagedFilter}
          options={createFilterOptions(
            Object.values(MISSION_MANAGED_FILTER_OPTIONS)
          )}
          displayValue={query.missionManaged}
          selection={query.missionManaged || []}
        />
        <FilterSelect
          ariaLabel="Accounts Filter"
          disabled={accounts.length === 0 || loading}
          initialValue="Accounts"
          multiple={true}
          onChange={handleSelectedAccountNames}
          onClear={handleDeselectAccountsFilter}
          options={createAccountOptions(accounts)}
          selection={query.accounts || []}
        />
        <FilterSelect
          ariaLabel="Status Filter"
          disabled={statuses.length === 0 || loading}
          initialValue="Status"
          multiple={true}
          onChange={handleSelectedStatus}
          onClear={handleDeselectStatusFilter}
          options={createFilterOptions(statuses)}
          selection={query.statuses || []}
        />
        <FilterSelect
          ariaLabel="Node Types Filter"
          disabled={statuses.length === 0 || loading}
          initialValue="Node Types"
          multiple={true}
          onChange={handleSelectedNodeTypes}
          onClear={handleDeselectNodeTypesFilter}
          options={createFilterOptions(nodeTypes)}
          selection={query.nodeTypes || []}
        />
      </FilterBar>
      <ResourcesTable
        defaultSort={query.sort}
        drawerDetails={drawerDetails}
        emptyMessage={GET_RESOURCES_EMPTY(REDSHIFT_LABEL)}
        headers={TABLE_HEADERS_REDSHIFT_CLUSTERS}
        label={REDSHIFT_LABEL}
        loading={loading}
        onCloseDrawer={handleCloseDrawer}
        onSortChange={handleSortChange}
        pagination={pagination}
        rows={rows}
      />
    </Page>
  );
};
