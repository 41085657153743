import { ArrowUpTrayIcon, BoltIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import {
  DateParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { Button, Card, DynamicSelect, Link } from "@m/ui";
import { dt } from "@m/utils";

import { PageHeading } from "@mc/components/PageHeading";
import { StaffOnly } from "@mc/components/StaffOnly";

import {
  useCompanyProductsDimensions,
  useExportMeteredCharges,
  useMeteredCharges,
} from "../api";

import {
  MarketplaceInvitationsButton,
  MeteredChargesTable,
  MeteredChargesTableFilter,
} from "./";

const METERED_CHARGES_HELP_ARTICLE_LINK =
  "https://resources.missioncloud.com/en/articles/10548263";

export const MeteredChargesSection = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    productCode: withDefault(StringParam, null),
    dimensionCode: withDefault(StringParam, null),
    startDate: withDefault(DateParam, dt.now().startOf("month").toJSDate()),
    endDate: withDefault(DateParam, dt.now().endOf("month").toJSDate()),
  });

  const { mutateAsync: startExport, isPending: loading } =
    useExportMeteredCharges(queryParams);

  const {
    data: { products, dimensions, earliestOnboardingTimestamp },
    loading: companyProductsDimensionsLoading,
  } = useCompanyProductsDimensions();

  const {
    data: { meteredCharges, dimensionTotals },
    pagination,
    loading: chargesLoading,
  } = useMeteredCharges(queryParams);

  const isLoading = chargesLoading || companyProductsDimensionsLoading;

  const monthOptions = useMemo(() => {
    const currentDate = dt.now().startOf("month");
    let date = dt.fromISO(earliestOnboardingTimestamp).startOf("month");

    const options = [];

    while (date <= currentDate) {
      options.push({
        label:
          date.hasSame(currentDate, "month") &&
          date.hasSame(currentDate, "year")
            ? "Current (unbilled)"
            : date.toFormat("MMMM yyyy"),
        startDate: date.startOf("month").toJSDate(),
        endDate: date.endOf("month").toJSDate(),
      });

      date = date.plus({ months: 1 });
    }

    return options.reverse();
  }, [earliestOnboardingTimestamp]);

  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState(
    monthOptions[0]
  );

  // If a month filter has already been applied via query params, we'll set the selected month based on the values
  useEffect(() => {
    const billingPeriod =
      monthOptions.find(
        ({ startDate }) =>
          startDate.toISOString() === queryParams.startDate?.toISOString()
      ) ?? monthOptions[0];

    setSelectedBillingPeriod(billingPeriod);
  }, [monthOptions, queryParams]);

  const handleBillingPeriodChange = (selectedMonthOption) => {
    const selectedMonth = monthOptions.find(
      (option) => option.label === selectedMonthOption.label
    );
    const { startDate, endDate } = selectedMonth || {};

    setSelectedBillingPeriod(selectedMonth || monthOptions[0]);
    setQueryParams({ ...queryParams, startDate, endDate });
  };

  const handleResetFilters = () => {
    setSelectedBillingPeriod(monthOptions[0]);
    setQueryParams({
      startDate: null,
      endDate: null,
      productCode: null,
      dimensionCode: null,
    });
  };

  return (
    <div data-testid="metered-charges-section" className="flex flex-col gap-3">
      <StaffOnly>
        <MarketplaceInvitationsButton />
      </StaffOnly>
      <div className="flex w-full flex-col">
        <PageHeading heading="Marketplace Charges Summary" />
        <div>
          Your Mission subscription is charged daily based on usage. See your
          marketplace agreement for details or learn more about{" "}
          <Link href={METERED_CHARGES_HELP_ARTICLE_LINK} target="_blank">
            Marketplace Charges Summary.
          </Link>
        </div>
      </div>
      <div className="flex w-1/3">
        <span className="my-1 whitespace-nowrap font-semibold text-subdued">
          Selected month
        </span>
        <DynamicSelect
          className="mx-1 w-[200px] p-0"
          id="billed-month"
          name="billed-month"
          aria-label="Budget Status"
          initialOptions={monthOptions.map(({ label }) => ({
            value: label,
            label,
          }))}
          value={{
            value: selectedBillingPeriod?.label,
            label: selectedBillingPeriod?.label,
          }}
          onSelectedOptionsChange={handleBillingPeriodChange}
        />
      </div>
      <div className="grid h-fit grid-cols-2 gap-2">
        {dimensionTotals.map(
          ({ dimensionCode, dimensionDescription, dimensionTotal }) =>
            dimensionTotal !== null && (
              <Card key={dimensionCode} className="flex h-full gap-1 p-1.5">
                <BoltIcon className="h-2.5 w-2.5" />
                <span className="font-semibold">
                  {dimensionDescription}:
                </span>{" "}
                {dimensionTotal?.toLocaleString()} Units Metered
              </Card>
            )
        )}
      </div>
      <div className="flex h-fit">
        <MeteredChargesTableFilter
          query={queryParams}
          dimensions={dimensions}
          eligibleProducts={products}
          handleQueryChange={setQueryParams}
          handleResetFilters={handleResetFilters}
          loading={isLoading}
        />
        <Button
          data-testid="export-metered-charges-button"
          kind="primary"
          fill="solid"
          size="small"
          className="mx-2 h-fit"
          leftIcon={ArrowUpTrayIcon}
          loading={loading}
          onClick={startExport}
        >
          Export
        </Button>
      </div>
      <MeteredChargesTable
        meteredCharges={meteredCharges}
        isLoading={isLoading}
        {...pagination}
      />
    </div>
  );
};
