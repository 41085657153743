import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { EksClusterFilter, EksClusterSortEnum } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { EKS_LABEL, GET_RESOURCES_FAILURE } from "../constants";

export interface EksClusterQueryParams {
  missionManaged: string;
  accounts: string[];
  sort: EksClusterSortEnum;
  search: string;
}

export const GET_EKS_CLUSTERS = gql(/* GraphQL */ `
  query EksClusters(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchFields: [String]
    $searchTerm: String
    $sort: [EksClusterSortEnum]
    $where: EksClusterFilter
  ) {
    eksClusters(
      after: $after
      before: $before
      first: $first
      last: $last
      searchFields: $searchFields
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          accountId
          accountName
          arn
          clusterName
          createTime
          id
          isMissionManaged
          region
          status
          tagTotal
          tags
        }
      }
    }
  }
`);

export const GET_EKS_CLUSTERS_CSV = gql(/* GraphQL */ `
  query EksClustersCsv(
    $searchFields: [String]
    $searchTerm: String
    $sort: [EksClusterSortEnum]
    $where: EksClusterFilter
  ) {
    eksClusters(
      searchFields: $searchFields
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      edges {
        node {
          Name: clusterName
          AccountName: accountName
          AccountID: accountId
          Region: region
          ARN: arn
          Status: status
          Created: createTime
          Tags: tags
        }
      }
    }
  }
`);

export const useEksClusters = (queryParams: EksClusterQueryParams) => {
  const onError = () => toast.error(GET_RESOURCES_FAILURE(EKS_LABEL));

  const { data, loading, error, fetchMore, refetch } = useQuery(
    GET_EKS_CLUSTERS,
    {
      onError,
      variables: getEksClustersVariables(queryParams),
      notifyOnNetworkStatusChange: true,
    }
  );

  const { edges, pageInfo, totalCount } = data?.eksClusters || {};
  const clusters = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { clusters },
    error,
    loading,
    pagination,
    refetch,
  };
};

export const getEksClustersVariables = (queryParams: EksClusterQueryParams) => {
  const where: EksClusterFilter = {};

  if (queryParams.missionManaged === "Managed") where.isMissionManaged = true;
  if (queryParams.missionManaged === "Unmanaged")
    where.isMissionManaged = false;
  if (queryParams.accounts) where.accountIn = queryParams.accounts;

  return {
    sort: queryParams.sort,
    ...INITIAL_PAGINATION,
    searchTerm: queryParams.search,
    where,
  };
};
