import { ArrowUpTrayIcon, CheckIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { DocumentNode } from "graphql";

import { Spinner } from "@m/ui";

import { useExport } from "@mc/api";

interface ExportResourcesButtonProps {
  disabled?: boolean;
  id: string;
  name?: string;
  query: DocumentNode;
  totalResources: number;
}

export const ExportResourcesButton = ({
  disabled = false,
  id,
  name = "resources",
  query,
  totalResources = 0,
}: ExportResourcesButtonProps) => {
  const {
    mutateAsync: startExport,
    isPending: loading,
    isSuccess,
    isError,
  } = useExport({
    query,
    variables: { id },
    exportFilename: `${name}.csv`,
  });

  const disableBtn = totalResources < 1 || disabled || isSuccess || isError;

  return loading ? (
    <Spinner />
  ) : (
    <button
      className={clsx("mt-0.5 flex space-x-1", {
        "text-action hover:text-blue-500": !disableBtn,
        "text-gray-400": disableBtn,
      })}
      disabled={disableBtn}
      onClick={() => startExport()}
      id="download-resources-button"
    >
      {isSuccess ? (
        <>
          <CheckIcon className="h-2.5 w-2.5 text-status-good" />
          <div className="text-sm font-semibold">Export successful</div>
        </>
      ) : (
        <>
          <ArrowUpTrayIcon className="h-2.5 w-2.5" />
          <div className="text-sm font-semibold">Export</div>
        </>
      )}
    </button>
  );
};
