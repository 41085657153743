// prettier-ignore
export const FEATURE_FLAGS = {
  ACCOUNTS_ALLOW_ADD_AWS_ACCOUNT: "add-aws-account",
  ACCOUNTS_ALLOW_CONNECT_AWS_ACCOUNT: "accounts-connect-aws-account",
  AWS_RESOURCES_ALLOW_ACCESS: "resources",
  BILLING_ALLOW_CREDIT_CARD_PAYMENT: "credit-card-payment-support",
  CLOUD_SCORE_ALLOW_ACCESS: "scorecards",
  CLOUD_SCORE_RECOMMENDATIONS_ALLOW_ACCESS: "scorecard-recommendations",
  DEVELOPER_SHOW_DIAGNOSTICS: "mission-control-diagnostics",
  ENGAGEMENTS_ALLOW_ACCESS: "engagements",
  ENGINEER_ASSIST_ALLOW_AI_DESK: "engineer-assist-ai-desk-allow-access",
  ENGINEER_ASSIST_ALLOW_DEVOPS_DESK: "devops-desk",
  RIO_REPORTING_ALLOW_ACCESS: "rio-reporting-allow-access",
  SETTINGS_ROLLOUT_SUBSCRIPTION_DETAILS_PAGE: "settings-rollout-subscription-details-page",
  SUPPORT_CASES_ALLOW_AWS_ACCOUNT_SUPPORT: "support-cases-allow-aws-account-support",
  SUPPORT_CASES_ALLOW_BILLING_SUPPORT: "support-cases-allow-billing-support",
  SUPPORT_CASES_ALLOW_CLOUD_GATEWAY_SUPPORT: "support-cases-allow-cloud-gateway-support",
  SUPPORT_CASES_ALLOW_CLOUD_SECURE_SUPPORT: "support-cases-allow-cloud-secure-support",
  SUPPORT_CASES_ALLOW_CLOUD_OPERATE_SUPPORT: "support-cases-allow-cloud-operate-support",
  SUPPORT_CASES_ALLOW_LIVE_CHAT: "mission-control-live-chat",
  SUPPORT_CASES_ALLOW_OPERATIONAL_SUPPORT: "operational-support",
  SUPPORT_CASES_ALLOW_TECHNICAL_SUPPORT: "technical-support",
};

export const SUPPORT_CASES_FEATURE_FLAGS = [
  FEATURE_FLAGS.SUPPORT_CASES_ALLOW_AWS_ACCOUNT_SUPPORT,
  FEATURE_FLAGS.SUPPORT_CASES_ALLOW_BILLING_SUPPORT,
  FEATURE_FLAGS.SUPPORT_CASES_ALLOW_CLOUD_GATEWAY_SUPPORT,
  FEATURE_FLAGS.SUPPORT_CASES_ALLOW_CLOUD_SECURE_SUPPORT,
  FEATURE_FLAGS.SUPPORT_CASES_ALLOW_CLOUD_OPERATE_SUPPORT,
  FEATURE_FLAGS.SUPPORT_CASES_ALLOW_OPERATIONAL_SUPPORT,
  FEATURE_FLAGS.SUPPORT_CASES_ALLOW_TECHNICAL_SUPPORT,
];

export const ENGINEER_ASSIST_FEATURE_FLAGS = [
  FEATURE_FLAGS.ENGINEER_ASSIST_ALLOW_AI_DESK,
  FEATURE_FLAGS.ENGINEER_ASSIST_ALLOW_DEVOPS_DESK,
];
