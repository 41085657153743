import { Activity, CommentAttachmentNode } from "@m/api/public/types";
import { downloadFile } from "@m/api/utils";
import { Avatar, toast } from "@m/ui";
import { abbreviateLastName, toTimeLocaleLong } from "@m/utils";

import { AttachmentPill } from "@mc/components/AttachmentPill/AttachmentPill";
import { EmailTrimmer } from "@mc/features/SupportCases/components/EmailTrimmer";

import { MESSAGES } from "../constants";

export function CommentActivity({ comment }: { comment: Activity }) {
  const {
    workNotes,
    type,
    sysId,
    createdAt,
    createdBy,
    commentAttachments,
    comment: commentText,
  } = comment || {};

  if (!commentText && !workNotes) {
    return null;
  }
  const rawComment = commentText || workNotes;
  const createdByName = createdBy.isStaff
    ? abbreviateLastName(createdBy.firstName, createdBy.lastName)
    : createdBy.name;

  return (
    <div data-testid="comment-activity" className="flex gap-1.5">
      <Avatar
        account={{
          ...createdBy,
        }}
        className="text-sm"
      />
      <div className="min-w-0 grow space-y-0.5" key={sysId}>
        <div className="flex items-center justify-between">
          <p className="text-sm font-semibold">{createdByName}</p>
          <p className="text-xs text-subdued">{toTimeLocaleLong(createdAt)}</p>
        </div>
        <div className="text-sm">
          <EmailTrimmer type={type} sysId={sysId}>
            {rawComment}
          </EmailTrimmer>
        </div>
        {commentAttachments?.length > 0 && (
          <div className="flex flex-wrap gap-1">
            {commentAttachments.map((attachment) => {
              return (
                <DownloadableAttachment
                  key={`attachment-${attachment.sysId}`}
                  attachment={attachment}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

const DownloadableAttachment = ({
  attachment,
}: {
  attachment: CommentAttachmentNode;
}) => {
  const { sysId, attachmentName, attachmentDownloadUrl } = attachment;

  const handleAttachmentClick = () => {
    toast.dismiss();
    if (attachmentDownloadUrl) {
      toast.success(MESSAGES.ATTACHMENT_DOWNLOADING);
      downloadFile(attachmentDownloadUrl);
    } else {
      toast.dismiss();
      toast.warning(MESSAGES.ATTACHMENT_PENDING);
    }
  };

  return (
    <AttachmentPill
      key={`attachment-${sysId}`}
      loading={false}
      fileName={attachmentName}
      className={"flex gap-1"}
      onClick={handleAttachmentClick}
    />
  );
};
