import { PaperAirplaneIcon } from "@heroicons/react/20/solid";

import { useAuth } from "@m/login";
import { Button, useModalState } from "@m/ui";

import { useEligibleProductsForInvitation } from "@mc/features/Onboarding/api";

import { MarketplaceInviteDialog } from "./";

export const MarketplaceInvitationsButton = () => {
  const {
    user: { company },
  } = useAuth();

  const { data: eligibleProductsForInvitation, loading } =
    useEligibleProductsForInvitation();

  const inviteDialog = useModalState();

  const invitationsDisabled = eligibleProductsForInvitation.length === 0;

  return (
    <>
      <Button
        kind="primary"
        fill="solid"
        className="h-fit w-fit"
        onClick={inviteDialog.open}
        loading={loading}
        disabled={invitationsDisabled}
      >
        <PaperAirplaneIcon className="h-2 w-2" />
        Send Private Offer
      </Button>

      {!invitationsDisabled && (
        <MarketplaceInviteDialog
          companyName={company.name}
          onClose={inviteDialog.close}
          open={inviteDialog.isOpen}
          productsForInvitation={eligibleProductsForInvitation}
        />
      )}
    </>
  );
};
