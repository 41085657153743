import { useMemo, useState } from "react";
import {
  ArrayParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { Ec2InstanceSortEnum } from "@m/api/public/types";
import {
  FilterBar,
  FilterSelect,
  Search,
  Tooltip,
  createFilterOptions,
  useSearchTerm,
} from "@m/ui";
import { formatFullDate } from "@m/utils";

import { Page } from "@mc/components/Page";

import {
  GET_EC2_INSTANCES_CSV,
  getEc2InstancesVariables,
  useEc2Instances,
  useEc2InstancesFilters,
  useEc2InstancesSearchTerms,
} from "../api";
import {
  ResourceDetails,
  ResourceTagsBadge,
  ResourcesPageHeader,
  ResourcesTable,
} from "../components";
import {
  AWS_RESOURCE_STATUS_TAGS,
  EC2_LABEL,
  GET_RESOURCES_EMPTY,
  MISSION_MANAGED_FILTER_ICON,
  MISSION_MANAGED_FILTER_LABEL,
  MISSION_MANAGED_FILTER_OPTIONS,
  TABLE_HEADERS_EC2_INSTANCES,
} from "../constants";
import { createAccountOptions } from "../utils";

export const Ec2InstancesPage = () => {
  const [query, setQuery] = useQueryParams({
    missionManaged: StringParam,
    accounts: ArrayParam,
    types: ArrayParam,
    states: ArrayParam,
    zones: ArrayParam,
    sort: withDefault(
      createEnumParam(Object.values(Ec2InstanceSortEnum)),
      Ec2InstanceSortEnum.CreateTimeDesc
    ),
    search: StringParam,
  });

  const {
    data: {
      filters: { accounts, types, states, zones },
    },
  } = useEc2InstancesFilters();

  const {
    data: { searchTerms },
    refetch: refetchSearchTerms,
  } = useEc2InstancesSearchTerms(query.search);

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ setQuery, refetchSearchTerms, query });

  /** keeping the query for the resource last simplifies testing with calls.lastQuery */
  const {
    data: { ec2Instances },
    loading,
    pagination,
  } = useEc2Instances(query);

  const [drawerDetails, setDrawerDetails] = useState<ResourceDetails | null>(
    null
  );

  const handleSortChange = (sort: Ec2InstanceSortEnum) => {
    pagination.setCurrentPage(1);
    setQuery({ sort });
  };

  const rows = useMemo(
    () =>
      ec2Instances.map((ec2Instance) => {
        const {
          instanceId = "",
          name = "",
          newrelicPermalink,
          tags = "{}",
          monitoringPolicy = "",
          backupPolicy = "",
          patchingPolicy = "",
          createTime,
          tagTotal,
        } = ec2Instance || {};

        const tagsData = JSON.parse(tags);
        const newrelic = NewRelicLink(newrelicPermalink);
        const monitoringPolicyTag =
          AWS_RESOURCE_STATUS_TAGS[monitoringPolicy] || "";
        const backupPolicyTag = AWS_RESOURCE_STATUS_TAGS[backupPolicy] || "";
        const patchingPolicyTag =
          AWS_RESOURCE_STATUS_TAGS[patchingPolicy] || "";
        const formattedTime = formatFullDate(createTime);

        return {
          ...ec2Instance,
          tags: (
            <ResourceTagsBadge
              onClick={setDrawerDetails}
              details={{
                instanceId,
                name,
                newrelicPermalink,
                tags: tagsData,
              }}
              count={tagTotal}
            />
          ),
          newrelic,
          monitoringPolicy: monitoringPolicyTag,
          backupPolicy: backupPolicyTag,
          patchingPolicy: patchingPolicyTag,
          createTime: formattedTime,
        };
      }),
    [ec2Instances]
  );

  const handleClearFilters = () => {
    clearSearchTerm();

    setQuery({
      missionManaged: undefined,
      accounts: undefined,
      types: undefined,
      states: undefined,
      zones: undefined,
      search: undefined,
    });
  };

  const handleSelectManaged = (missionManaged: string) => {
    if (missionManaged === query.missionManaged) {
      setQuery({
        missionManaged: undefined,
      });
    } else {
      setQuery({
        missionManaged: missionManaged,
      });
    }
  };

  const handleDeselectManagedFilter = () => {
    setQuery({
      missionManaged: undefined,
    });
  };

  const handleSelectedAccountNames = (accountIds: string[]) => {
    setQuery({
      accounts: accountIds,
    });
  };

  const handleDeselectAccountsFilter = () => {
    setQuery({
      accounts: undefined,
    });
  };

  const handleSelectedTypes = (types: string[]) => {
    setQuery({
      types,
    });
  };

  const handleDeselectTypesFilter = () => {
    setQuery({
      types: undefined,
    });
  };

  const handleSelectedStates = (states: string[]) => {
    setQuery({
      states,
    });
  };

  const handleDeselectStatesFilter = () => {
    setQuery({
      states: undefined,
    });
  };

  const handleSelectedZones = (zones: string[]) => {
    setQuery({
      zones,
    });
  };

  const handleDeselectZonesFilter = () => {
    setQuery({
      zones: undefined,
    });
  };

  const hasActiveFilters =
    !!query.missionManaged ||
    query.accounts?.length > 0 ||
    query.types?.length > 0 ||
    query.states?.length > 0 ||
    query.zones?.length > 0 ||
    !!searchTerm;

  return (
    <Page
      width="full"
      title={
        <ResourcesPageHeader
          title={EC2_LABEL}
          exportQuery={GET_EC2_INSTANCES_CSV}
          exportVariables={getEc2InstancesVariables(query)}
        />
      }
    >
      <FilterBar
        isActive={hasActiveFilters}
        onResetFilters={handleClearFilters}
        className="mb-2 p-1"
      >
        <Search
          ariaLabel="EC2 Instance Search Input"
          dropdownOptions={searchTerms}
          handleSearchInputChange={handleSearchInputChange}
          handleSubmitSearch={handleSubmitSearch}
          searchTerm={searchTerm}
        />
        <FilterSelect
          ariaLabel={MISSION_MANAGED_FILTER_LABEL}
          disabled={loading}
          icon={MISSION_MANAGED_FILTER_ICON}
          initialValue={MISSION_MANAGED_FILTER_OPTIONS.MANAGED}
          onChange={handleSelectManaged}
          onClear={handleDeselectManagedFilter}
          options={createFilterOptions(
            Object.values(MISSION_MANAGED_FILTER_OPTIONS)
          )}
          displayValue={query.missionManaged}
          selection={query.missionManaged || []}
        />
        <FilterSelect
          ariaLabel="Accounts Filter"
          disabled={accounts.length === 0 || loading}
          initialValue="Accounts"
          multiple={true}
          onChange={handleSelectedAccountNames}
          onClear={handleDeselectAccountsFilter}
          options={createAccountOptions(accounts)}
          selection={query.accounts || []}
        />
        <FilterSelect
          ariaLabel="Types Filter"
          disabled={types.length === 0 || loading}
          initialValue="Type"
          multiple={true}
          onChange={handleSelectedTypes}
          onClear={handleDeselectTypesFilter}
          options={createFilterOptions(types)}
          selection={query.types || []}
        />
        <FilterSelect
          ariaLabel="States Filter"
          disabled={states.length === 0 || loading}
          initialValue="State"
          multiple={true}
          onChange={handleSelectedStates}
          onClear={handleDeselectStatesFilter}
          options={createFilterOptions(states)}
          selection={query.states || []}
        />
        <FilterSelect
          ariaLabel="Zones Filter"
          disabled={zones.length === 0 || loading}
          initialValue="Zone"
          multiple={true}
          onChange={handleSelectedZones}
          onClear={handleDeselectZonesFilter}
          options={createFilterOptions(zones)}
          selection={query.zones || []}
        />
      </FilterBar>
      <ResourcesTable
        defaultSort={query.sort}
        drawerDetails={drawerDetails}
        emptyMessage={GET_RESOURCES_EMPTY(EC2_LABEL)}
        headers={TABLE_HEADERS_EC2_INSTANCES}
        label={EC2_LABEL}
        loading={loading}
        onCloseDrawer={() => setDrawerDetails(null)}
        onSortChange={handleSortChange}
        pagination={pagination}
        rows={rows}
      />
    </Page>
  );
};

const NewRelicLink = (url: string) => {
  if (url)
    return (
      <Tooltip content="View in New Relic" side="bottom">
        <div className="flex">
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="min-w-2 w-2 cursor-pointer"
          >
            <img alt="View in New Relic" src="/icons/nr-logo.png" />
          </a>
        </div>
      </Tooltip>
    );
  return null;
};
