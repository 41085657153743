import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import {
  ApplicationLoadBalancerFilter,
  ApplicationLoadBalancerSortEnum,
} from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import {
  GET_RESOURCES_FAILURE,
  LOAD_BALANCER_TYPES,
  NLB_LABEL,
} from "../constants";

export interface NetworkLoadBalancerQueryParams {
  missionManaged: string;
  accounts: string[];
  regions: string[];
  states: string[];
  sort: ApplicationLoadBalancerSortEnum;
  search: string;
}

export const GET_NETWORK_LOAD_BALANCERS = gql(/* GraphQL */ `
  query NetworkLoadBalancers(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $loadBalancerType: String
    $searchTerm: String
    $sort: [ApplicationLoadBalancerSortEnum]
    $where: ApplicationLoadBalancerFilter
  ) {
    loadBalancers(
      after: $after
      before: $before
      first: $first
      last: $last
      loadBalancerType: $loadBalancerType
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          name
          stateCode
          account
          accountId
          accountName
          region
          vpcId
          endpoint
          createTime
          monitoringPolicy
          tagTotal
          tags
        }
      }
    }
  }
`);

export const GET_NETWORK_LOAD_BALANCERS_CSV = gql(/* GraphQL */ `
  query NetworkLoadBalancersCsv(
    $loadBalancerType: String
    $searchTerm: String
    $sort: [ApplicationLoadBalancerSortEnum]
    $where: ApplicationLoadBalancerFilter
  ) {
    loadBalancers(
      loadBalancerType: $loadBalancerType
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) @csv {
      edges {
        node {
          Name: name
          AccountName: accountName
          AccountID: accountId
          State: stateCode
          Region: region
          DNSName: endpoint
          VPC: vpcId
          Created: createTime
          Monitoring: monitoringPolicy
          Tags: tags
        }
      }
    }
  }
`);

export const useNetworkLoadBalancers = (
  queryParams: NetworkLoadBalancerQueryParams
) => {
  const onError = () => toast.error(GET_RESOURCES_FAILURE(NLB_LABEL));

  const { data, loading, error, fetchMore } = useQuery(
    GET_NETWORK_LOAD_BALANCERS,
    {
      onError,
      variables: getNetworkLoadBalancersVariables(queryParams),
    }
  );

  const { edges, pageInfo, totalCount } = data?.loadBalancers || {};
  const networkLoadBalancers = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { networkLoadBalancers },
    error,
    loading,
    pagination,
  };
};

export const getNetworkLoadBalancersVariables = (
  queryParams: NetworkLoadBalancerQueryParams
) => {
  const where: ApplicationLoadBalancerFilter = {};

  if (queryParams.missionManaged === "Managed") where.isMissionManaged = true;
  if (queryParams.missionManaged === "Unmanaged")
    where.isMissionManaged = false;
  if (queryParams.accounts) where.accountIn = queryParams.accounts;
  if (queryParams.regions) where.regionIn = queryParams.regions;
  if (queryParams.states) where.stateCodeIn = queryParams.states;

  return {
    sort: queryParams.sort,
    ...INITIAL_PAGINATION,
    loadBalancerType: LOAD_BALANCER_TYPES.NETWORK,
    searchTerm: queryParams.search,
    where,
  };
};
