import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { EcsClusterFilter, EcsClusterSortEnum } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { ECS_LABEL, GET_RESOURCES_FAILURE } from "../constants";

export interface EcsClustersQueryParams {
  missionManaged: string;
  accounts: string[];
  sort: EcsClusterSortEnum;
  search: string;
}

export const GET_ECS_CLUSTERS = gql(/* GraphQL */ `
  query EcsClusters(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $where: EcsClusterFilter
    $searchTerm: String
    $searchFields: [String]
    $sort: [EcsClusterSortEnum]
  ) {
    ecsClusters(
      first: $first
      after: $after
      last: $last
      before: $before
      sort: $sort
      where: $where
      searchTerm: $searchTerm
      searchFields: $searchFields
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          accountId
          accountName
          clusterArn
          clusterName
          id
          isMissionManaged
          region
          status
          tagTotal
          tags
        }
      }
    }
  }
`);

export const GET_ECS_CLUSTERS_CSV = gql(/* GraphQL */ `
  query EcsClustersCsv(
    $where: EcsClusterFilter
    $searchTerm: String
    $searchFields: [String]
    $sort: [EcsClusterSortEnum]
  ) {
    ecsClusters(
      sort: $sort
      where: $where
      searchTerm: $searchTerm
      searchFields: $searchFields
    ) @csv {
      edges {
        node {
          Name: clusterName
          AccountName: accountName
          AccountID: accountId
          Region: region
          ARN: clusterArn
          Status: status
          Tags: tags
        }
      }
    }
  }
`);

export const useEcsClusters = (queryParams) => {
  const onError = () => toast.error(GET_RESOURCES_FAILURE(ECS_LABEL));

  const { data, loading, error, fetchMore, refetch } = useQuery(
    GET_ECS_CLUSTERS,
    {
      onError,
      variables: getEcsClustersVariables(queryParams),
      notifyOnNetworkStatusChange: true,
    }
  );

  const { edges, pageInfo, totalCount } = data?.ecsClusters || {};
  const clusters = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { clusters },
    error,
    loading,
    pagination,
    refetch,
  };
};

export const getEcsClustersVariables = (
  queryParams: EcsClustersQueryParams
) => {
  const where: EcsClusterFilter = {};

  if (queryParams.missionManaged === "Managed") where.isMissionManaged = true;
  if (queryParams.missionManaged === "Unmanaged")
    where.isMissionManaged = false;
  if (queryParams.accounts) where.accountIn = queryParams.accounts;

  return {
    sort: queryParams.sort,
    ...INITIAL_PAGINATION,
    searchTerm: queryParams.search,
    where,
  };
};
