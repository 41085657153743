import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import {
  RedshiftClusterFilter,
  RedshiftClusterSortEnum,
} from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { GET_RESOURCES_FAILURE, REDSHIFT_LABEL } from "../constants";

export interface RedshiftClusterQueryParams {
  missionManaged: string;
  accounts: string[];
  statuses: string[];
  nodeTypes: string[];
  sort: RedshiftClusterSortEnum;
  search: string;
}

export const GET_REDSHIFT_CLUSTERS = gql(/* GraphQL */ `
  query RedshiftClusters(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchFields: [String]
    $searchTerm: String
    $sort: [RedshiftClusterSortEnum]
    $where: RedshiftClusterFilter
  ) {
    redshiftClusters(
      after: $after
      before: $before
      first: $first
      last: $last
      searchFields: $searchFields
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          account
          accountId
          accountName
          availabilityZone
          clusterName
          createTime
          id
          isMissionManaged
          nodeType
          region
          status
          tagTotal
          tags
        }
      }
    }
  }
`);

export const GET_REDSHIFT_CLUSTERS_CSV = gql(/* GraphQL */ `
  query RedshiftClustersCsv(
    $searchFields: [String]
    $searchTerm: String
    $sort: [RedshiftClusterSortEnum]
    $where: RedshiftClusterFilter
  ) {
    redshiftClusters(
      searchFields: $searchFields
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) @csv {
      edges {
        node {
          Name: clusterName
          AccountName: accountName
          AccountID: accountId
          NodeType: nodeType
          Status: status
          Zone: availabilityZone
          Region: region
          Created: createTime
          Tags: tags
        }
      }
    }
  }
`);

export const useRedshiftClusters = (
  queryParams: RedshiftClusterQueryParams
) => {
  const onError = () => toast.error(GET_RESOURCES_FAILURE(REDSHIFT_LABEL));

  const { data, loading, error, fetchMore, refetch } = useQuery(
    GET_REDSHIFT_CLUSTERS,
    {
      onError,
      variables: getRedshiftClustersVariables(queryParams),
    }
  );

  const { edges, pageInfo, totalCount } = data?.redshiftClusters || {};
  const clusters = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { clusters },
    error,
    loading,
    pagination,
    refetch,
  };
};

export const getRedshiftClustersVariables = (
  queryParams: RedshiftClusterQueryParams
) => {
  const where: RedshiftClusterFilter = {};

  if (queryParams.missionManaged === "Managed") where.isMissionManaged = true;
  if (queryParams.missionManaged === "Unmanaged")
    where.isMissionManaged = false;
  if (queryParams.accounts) where.accountIn = queryParams.accounts;
  if (queryParams.statuses) where.statusIn = queryParams.statuses;
  if (queryParams.nodeTypes) where.nodeTypeIn = queryParams.nodeTypes;
  return {
    sort: queryParams.sort,
    ...INITIAL_PAGINATION,
    searchTerm: queryParams.search,
    where,
  };
};
