import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { Ec2InstanceFilter, Ec2InstanceSortEnum } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { EC2_LABEL, GET_RESOURCES_FAILURE } from "../constants";

export interface Ec2InstanceQueryParams {
  missionManaged: string;
  accounts: string[];
  types: string[];
  states: string[];
  zones: string[];
  sort: Ec2InstanceSortEnum;
  search: string;
}

export const GET_EC2_INSTANCES = gql(/* GraphQL */ `
  query Ec2Instances(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchTerm: String
    $sort: [Ec2InstanceSortEnum]
    $where: Ec2InstanceFilter
  ) {
    ec2Instances(
      after: $after
      before: $before
      first: $first
      last: $last
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          account
          id
          instanceId
          instanceType
          availabilityZone
          publicIp
          privateIp
          platform
          accountName
          vpcId
          state
          createTime
          tagTotal
          tags
          name
          newrelicPermalink
          monitoringPolicy
          backupPolicy
          patchingPolicy
        }
      }
    }
  }
`);

export const GET_EC2_INSTANCES_CSV = gql(/* GraphQL */ `
  query Ec2InstancesCsv(
    $searchTerm: String
    $sort: [Ec2InstanceSortEnum]
    $where: Ec2InstanceFilter
  ) {
    ec2Instances(searchTerm: $searchTerm, sort: $sort, where: $where) @csv {
      edges {
        node {
          Name: name
          ID: instanceId
          AccountName: accountName
          AccountID: account
          Type: instanceType
          Zone: availabilityZone
          PublicIP: publicIp
          PrivateIP: privateIp
          Platform: platform
          VPC: vpcId
          State: state
          Created: createTime
          Monitoring: monitoringPolicy
          Backup: backupPolicy
          Patching: patchingPolicy
          tags: tags
        }
      }
    }
  }
`);

export const useEc2Instances = (queryParams: Ec2InstanceQueryParams) => {
  const onError = () => toast.error(GET_RESOURCES_FAILURE(EC2_LABEL));

  const { data, loading, error, fetchMore } = useQuery(GET_EC2_INSTANCES, {
    variables: getEc2InstancesVariables(queryParams),
    onError,
    notifyOnNetworkStatusChange: true,
  });

  const { edges, pageInfo, totalCount } = data?.ec2Instances || {};
  const ec2Instances = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { ec2Instances },
    error,
    loading,
    pagination,
  };
};

export const getEc2InstancesVariables = (
  queryParams: Ec2InstanceQueryParams
) => {
  const where: Ec2InstanceFilter = {};

  if (queryParams.missionManaged === "Managed") where.isMissionManaged = true;
  if (queryParams.missionManaged === "Unmanaged")
    where.isMissionManaged = false;
  if (queryParams.accounts) where.accountIn = queryParams.accounts;
  if (queryParams.types) where.instanceTypeIn = queryParams.types;
  if (queryParams.states) where.stateIn = queryParams.states;
  if (queryParams.zones) where.availabilityZoneIn = queryParams.zones;

  return {
    sort: queryParams.sort,
    ...INITIAL_PAGINATION,
    searchTerm: queryParams.search,
    where,
  };
};
