import { SKUS } from "@mc/constants";
import { SubscriptionDetailsCard } from "@mc/features/SubscriptionSettings/components";

import {
  AwsPayerAccountOnboardingWelcomeDetails,
  CloudScoreOnboardingWelcomeDetails,
  CompleteOnboardingWelcomeDetails,
} from "../components";
import { usePendingOnboarding } from "../hooks";
import { WelcomePageArtboard } from "../icons";

export const OnboardingWelcomePage = () => {
  const pendingOnboarding = usePendingOnboarding();

  return (
    <div
      className="flex flex-col items-center justify-center gap-4"
      data-testid="onboarding-welcome-page"
    >
      <WelcomePageArtboard />

      <div className="flex flex-col items-center justify-center gap-2">
        <div className="text-center text-4xl font-bold text-default">
          Say hello to Mission Control
        </div>
        <div className="max-w-sm text-center text-xl font-normal text-subdued">
          Thank you for signing up!
        </div>
      </div>

      <div className="flex w-full max-w-sm flex-col items-center justify-center gap-5">
        <SubscriptionDetailsCard
          expiresAt={pendingOnboarding.expirationTime}
          iamExternalId={pendingOnboarding.iamExternalId}
          productDisplayName={pendingOnboarding.catalogItem.displayName}
          registrationStatus={pendingOnboarding.registrationStatus}
          onboardingStatus={pendingOnboarding.onboardingStatus}
          subscribedAccountId={pendingOnboarding.awsAccountId}
          subscribedAt={pendingOnboarding.subscribeTime}
        />

        <WelcomeDetails sku={pendingOnboarding.sku} />
      </div>
    </div>
  );
};

const WelcomeDetails = ({ sku }) => {
  switch (sku) {
    case SKUS.CLOUD_SCORE:
      return <CloudScoreOnboardingWelcomeDetails />;
    case SKUS.CLOUD_GATEWAY:
    case SKUS.AI_GATEWAY:
    case SKUS.CLOUD_SECURE:
    case SKUS.CLOUD_OPERATE:
      return <AwsPayerAccountOnboardingWelcomeDetails sku={sku} />;
    case SKUS.ENGAGEMENTS_DEVOPS:
    case SKUS.ENGAGEMENTS_GENAI:
      return <CompleteOnboardingWelcomeDetails sku={sku} />;
    default:
      return null;
  }
};
