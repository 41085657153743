import { toHyphenLowercase } from "@m/utils";

import { useExport } from "@mc/api";

export const useExportResources = ({
  query,
  resourceName,
  variables,
}: {
  query: any;
  resourceName: string;
  variables: any;
}) => {
  return useExport({
    query,
    variables,
    exportFilename: toHyphenLowercase(`${resourceName}.csv`),
  });
};
