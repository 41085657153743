import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import {
  ElasticacheClusterSortEnum,
  ElasticacheClustersFilter,
} from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { ELASTICACHE_LABEL, GET_RESOURCES_FAILURE } from "../constants";

export interface ElasticacheClustersQueryParams {
  missionManaged: string;
  accounts: string[];
  sort: ElasticacheClusterSortEnum;
  search: string;
}

export const GET_ELASTICACHE_CLUSTERS = gql(/* GraphQL */ `
  query ElasticacheClusters(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchTerm: String
    $sort: [ElasticacheClusterSortEnum]
    $where: ElasticacheClustersFilter
  ) {
    elasticacheClusters(
      after: $after
      before: $before
      first: $first
      last: $last
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          accountId
          accountName
          availabilityZone
          cacheClusterName
          createTime
          engine
          id
          isMissionManaged
          nodeType
          region
          status
          tagTotal
          tags
        }
      }
    }
  }
`);

export const GET_ELASTICACHE_CLUSTERS_CSV = gql(/* GraphQL */ `
  query ElasticacheClustersCsv(
    $searchTerm: String
    $sort: [ElasticacheClusterSortEnum]
    $where: ElasticacheClustersFilter
  ) {
    elasticacheClusters(searchTerm: $searchTerm, sort: $sort, where: $where)
      @csv {
      edges {
        node {
          Name: cacheClusterName
          AccountName: accountName
          AccountID: accountId
          Engine: engine
          NodeType: nodeType
          Region: region
          Zone: availabilityZone
          Created: createTime
          Tags: tags
        }
      }
    }
  }
`);

export const useElasticacheClusters = (
  queryParams: ElasticacheClustersQueryParams
) => {
  const onError = () => toast.error(GET_RESOURCES_FAILURE(ELASTICACHE_LABEL));

  const { data, loading, error, fetchMore } = useQuery(
    GET_ELASTICACHE_CLUSTERS,
    {
      variables: getElasticacheClustersVariables(queryParams),
      onError,
      notifyOnNetworkStatusChange: true,
    }
  );

  const { edges, pageInfo, totalCount } = data?.elasticacheClusters || {};
  const clusters = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return { data: { clusters }, loading, error, pagination };
};

export const getElasticacheClustersVariables = (
  queryParams: ElasticacheClustersQueryParams
) => {
  const where: ElasticacheClustersFilter = {};

  if (queryParams.missionManaged === "Managed") where.isMissionManaged = true;
  if (queryParams.missionManaged === "Unmanaged")
    where.isMissionManaged = false;
  if (queryParams.accounts) where.accountIn = queryParams.accounts;

  return {
    sort: queryParams.sort,
    ...INITIAL_PAGINATION,
    searchTerm: queryParams.search,
    where,
  };
};
