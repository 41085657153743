import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import {
  AutoScalingGroupFilter,
  AutoScalingGroupSortEnum,
} from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { ASG_LABEL, GET_RESOURCES_FAILURE } from "../constants";

export interface AutoScalingGroupsQueryParams {
  missionManaged: string;
  accounts: string[];
  sort: AutoScalingGroupSortEnum;
  search: string;
}

export const GET_AUTO_SCALING_GROUPS = gql(/* GraphQL */ `
  query AutoScalingGroups(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchTerm: String
    $sort: [AutoScalingGroupSortEnum]
    $where: AutoScalingGroupFilter
  ) {
    autoScalingGroups(
      after: $after
      before: $before
      first: $first
      last: $last
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          accountName
          accountId
          name
          minSize
          maxSize
          desiredCapacity
          availabilityZones
          tagTotal
          tags
          createTime
        }
      }
    }
  }
`);

export const GET_AUTO_SCALING_GROUPS_CSV = gql(/* GraphQL */ `
  query AutoScalingGroupsCsv(
    $searchTerm: String
    $sort: [AutoScalingGroupSortEnum]
    $where: AutoScalingGroupFilter
  ) {
    autoScalingGroups(searchTerm: $searchTerm, sort: $sort, where: $where)
      @csv {
      edges {
        node {
          Name: name
          AccountName: accountName
          AccountID: accountId
          Min: minSize
          Max: maxSize
          Desired: desiredCapacity
          AvailabilityZones: availabilityZones
          Created: createTime
          Tags: tags
        }
      }
    }
  }
`);

export const useAutoScalingGroups = (
  queryParams: AutoScalingGroupsQueryParams
) => {
  const onError = () => toast.error(GET_RESOURCES_FAILURE(ASG_LABEL));

  const { data, loading, error, fetchMore } = useQuery(
    GET_AUTO_SCALING_GROUPS,
    {
      variables: getAutoScalingGroupsVariables(queryParams),
      onError,
      notifyOnNetworkStatusChange: true,
    }
  );

  const { edges, pageInfo, totalCount } = data?.autoScalingGroups || {};
  const autoScalingGroups = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { autoScalingGroups },
    error,
    loading,
    pagination,
  };
};

export const getAutoScalingGroupsVariables = (
  queryParams: AutoScalingGroupsQueryParams
) => {
  const where: AutoScalingGroupFilter = {};

  if (queryParams.missionManaged === "Managed") where.isMissionManaged = true;
  if (queryParams.missionManaged === "Unmanaged")
    where.isMissionManaged = false;
  if (queryParams.accounts) where.accountIn = queryParams.accounts;

  return {
    sort: queryParams.sort,
    ...INITIAL_PAGINATION,
    searchTerm: queryParams.search,
    where,
  };
};
