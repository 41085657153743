import { LinkIcon, PaperAirplaneIcon } from "@heroicons/react/20/solid";
import { ChangeEvent, FormEventHandler, useMemo, useState } from "react";

import { EngagementSourceType } from "@m/api/public/types";
import {
  Button,
  DynamicSelect,
  Field,
  Fields,
  Input,
  Modal,
  OptionType,
} from "@m/ui";

import { PROJECT_SELECTION_ALLOWED_PRODUCT_SKUS } from "@mc/constants";
import {
  EligibleProductsForInvitationType,
  useCompanyWorkspaces,
  useCreateMarketplaceInvitation,
} from "@mc/features/Onboarding/api";
import { WORK_EMAIL_PATTERN } from "@mc/utils/emails";

interface Props {
  companyName: string;
  onClose: () => void;
  open: boolean;
  productsForInvitation: EligibleProductsForInvitationType;
}

export const MarketplaceInviteDialog = ({
  companyName,
  onClose,
  open,
  productsForInvitation,
}: Props) => {
  const [createMarketplaceInvitation, { loading }] =
    useCreateMarketplaceInvitation();

  const {
    data: { workspaces },
  } = useCompanyWorkspaces();

  const [selectedProduct, setSelectedProduct] = useState({
    value: productsForInvitation[0]?.sku,
    label: productsForInvitation[0]?.displayName,
  });
  const [offerUrl, setOfferUrl] = useState(null);

  const workspacesOptions = useMemo(() => {
    return workspaces.map((workspace) => {
      if (!workspace.isAssociatedWithEngagement) {
        return { value: workspace.id, label: workspace.title };
      }
    });
  }, [workspaces]);

  const productOptions = useMemo(
    () =>
      productsForInvitation.map((product) => ({
        value: product.sku,
        label: product.displayName,
      })),
    [productsForInvitation]
  );

  const handleChangeProductSku = (selectedProduct: OptionType) => {
    setSelectedProduct(selectedProduct);
  };

  const handleChangeOfferUrl = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOfferUrl(value === "" ? null : value);
  };

  const handleSubmit: FormEventHandler<MarketplaceInviteFormElement> = (e) => {
    e.preventDefault();

    const {
      emailInput,
      productSelect,
      awsAccountIdInput,
      privateOfferUrlInput,
      projectSelect,
    } = e.currentTarget.elements;

    const sourceId = projectSelect?.value;

    createMarketplaceInvitation({
      email: emailInput.value,
      awsAccountId: awsAccountIdInput?.value,
      privateOfferUrl: privateOfferUrlInput?.value,
      sku: productSelect.value,
      ...(sourceId && {
        sourceId,
        sourceType: EngagementSourceType.KantataWorkspace,
      }),
    });
    onClose();
  };

  const shouldShowProjectField =
    PROJECT_SELECTION_ALLOWED_PRODUCT_SKUS.includes(selectedProduct.value);

  return (
    <Modal
      header={<Modal.Title>Send New Private Offer</Modal.Title>}
      open={open}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit}>
        <Fields>
          <Field label="Company" htmlFor="company">
            <Input value={companyName} disabled={true} id="company" />
          </Field>
          <Field label="Email" htmlFor="email">
            <Input
              id="email"
              name="emailInput"
              pattern={WORK_EMAIL_PATTERN}
              placeholder="user@domain.com"
              required={true}
              title="Please enter a valid company email address."
              type="email"
            />
          </Field>
          <Field label="Account ID" htmlFor="aws-account-id">
            <Input
              id="aws-account-id"
              name="awsAccountIdInput"
              required={true}
              maxLength={12}
              minLength={12}
              pattern="^\d{12}$"
              title="Enter a valid AWS account ID"
            />
          </Field>
          <Field label="Marketplace Product" htmlFor="product">
            <DynamicSelect
              id="product"
              name="productSelect"
              aria-label="Marketplace Product"
              initialOptions={productOptions}
              value={selectedProduct}
              onSelectedOptionsChange={handleChangeProductSku}
            />
          </Field>
          {shouldShowProjectField && (
            <Field label="Kantata Project" htmlFor="project">
              <DynamicSelect
                id="project"
                name="projectSelect"
                aria-label="Kantata Project"
                initialOptions={workspacesOptions}
                required
              />
            </Field>
          )}
          <Field label="Private Offer URL" htmlFor="private-offer-url">
            <Input
              id="private-offer-url"
              name="privateOfferUrlInput"
              onChange={handleChangeOfferUrl}
              placeholder="https://aws.amazon.com/marketplace/pp/prodview-123456789abcde"
              required={true}
              leftIcon={LinkIcon}
            />
          </Field>
        </Fields>
        <Button
          className="mt-4 w-full"
          kind="primary"
          loading={loading}
          type="submit"
          disabled={offerUrl === null}
        >
          <PaperAirplaneIcon className="h-2 w-2" />
          Send Offer
        </Button>
      </form>
    </Modal>
  );
};

interface MarketplaceInviteFormElements extends HTMLFormControlsCollection {
  emailInput: HTMLInputElement;
  productSelect: HTMLSelectElement;
  privateOfferUrlInput?: HTMLInputElement;
  awsAccountIdInput?: HTMLInputElement;
  projectSelect: HTMLSelectElement;
}

interface MarketplaceInviteFormElement extends HTMLFormElement {
  readonly elements: MarketplaceInviteFormElements;
}
