import { useMutation } from "@tanstack/react-query";
import { DocumentNode, print } from "graphql";

import { useAuth } from "@m/login";
import { toast } from "@m/ui";

import { PUBLIC_GRAPHQL_URL } from "@mc/constants";

interface UseExportProps {
  query: DocumentNode;
  variables: Record<string, any>;
  exportFilename: string;
}

export const useExport = ({
  query,
  variables,
  exportFilename,
}: UseExportProps) => {
  const { user } = useAuth();

  // Remove pagination variables to ensure the export includes all results rather than just the current page
  const exportVariables = { ...variables };
  delete exportVariables.first;
  delete exportVariables.last;
  delete exportVariables.before;
  delete exportVariables.after;

  return useMutation({
    mutationFn: () => exportQuery(exportFilename, query, exportVariables),
    onSuccess: () =>
      toast.success(
        `Your report is being generated and will be emailed to ${user.email} shortly.`,
        { autoClose: false }
      ),
    onError: () =>
      toast.error("There was a problem. Please, try again later.", {
        autoClose: false,
      }),
  });
};

const exportQuery = async (
  exportFilename: string,
  query: DocumentNode,
  variables: Record<string, any>
) => {
  const response = await fetch(PUBLIC_GRAPHQL_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": localStorage.getItem("csrf"),
      "X-EXPORT-ME": exportFilename,
    },
    credentials: "include",
    body: JSON.stringify({
      query: print(query),
      variables,
    }),
  });

  const body = await response.json();

  if (!response.ok) throw new Error(body.message);

  return body;
};
