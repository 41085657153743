import { SERVICES_API_URL } from "@mc/constants";

export const getBatchUploadUrl = (
  caseSysId: string,
  commentSysId: string
): string =>
  SERVICES_API_URL.concat(
    `/api/public/v1/case-attachment/${caseSysId}${
      commentSysId ? `/comment/${commentSysId}` : ""
    }`
  );

export const batchUploadAttachments = async (
  files: File[],
  caseSysId: string,
  commentSysId: string
) => {
  const filePromises = files.map(
    (file) =>
      new Promise((resolve, reject) => {
        uploadAttachment(file, caseSysId, commentSysId)
          .then((result) => {
            resolve({ file, result });
          })
          .catch((error) => {
            reject({ file, error });
          });
      })
  );
  return await Promise.allSettled(filePromises);
};

export const uploadAttachment = async (
  file: File,
  caseSysId: string,
  commentSysId: string
) => {
  const url = getBatchUploadUrl(caseSysId, commentSysId);

  /*
   * To upload files using fetch and FormData, you must not set Content-Type header.
   * https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
   */
  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: formData,
    // If you add this, upload won't work
    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
  });
  const data = await response.json();

  if (!data.ok) {
    throw Error(data.message);
  }
  return data;
};
