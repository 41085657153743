import { Activity } from "@m/api/public/types";
import { downloadFile } from "@m/api/utils";
import { Avatar, Button, Confirm, toast, useConfirmState } from "@m/ui";
import { abbreviateLastName, toTimeLocaleLong } from "@m/utils";

import { AttachmentPill } from "@mc/components/AttachmentPill";

import { useDeleteAttachment } from "../api";
import { MESSAGES } from "../constants";

export function AttachmentActivity({ activity }: { activity: Activity }) {
  const { sysId, attachmentName, createdAt, createdBy, attachmentDownloadUrl } =
    activity;

  const deleteDialog = useConfirmState();

  const handleAttachmentClick = () => {
    toast.dismiss();
    if (attachmentDownloadUrl) {
      toast.success(MESSAGES.ATTACHMENT_DOWNLOADING);
      downloadFile(attachmentDownloadUrl);
    } else {
      toast.warning(MESSAGES.ATTACHMENT_PENDING);
    }
  };
  const createdByName = createdBy.isStaff
    ? abbreviateLastName(createdBy.firstName, createdBy.lastName)
    : createdBy.name;

  return (
    <div data-testid="attachment-activity" className="flex gap-1.5">
      <Avatar
        account={{
          ...createdBy,
        }}
        className="text-sm"
      />
      <div className="grow space-y-0.5" key={sysId}>
        <div className="flex items-center justify-between">
          <p className="text-sm font-semibold">{createdByName}</p>
          <p className="text-xs text-subdued">{toTimeLocaleLong(createdAt)}</p>
        </div>
        <AttachmentPill
          fileName={attachmentName}
          className={"flex"}
          onRemove={deleteDialog.open}
          onClick={handleAttachmentClick}
          loading={false}
        />
      </div>
      {deleteDialog.isOpen && (
        <DeleteAttachmentDialog
          open={deleteDialog.isOpen}
          onClose={deleteDialog.close}
          fileName={activity.attachmentName}
          activity={activity}
        />
      )}
    </div>
  );
}

const DeleteAttachmentDialog = ({
  open,
  onClose,
  fileName,
  activity,
}: {
  open: boolean;
  onClose: () => void;
  fileName: string;
  activity: Activity;
}) => {
  const [deleteAttachment, { loading }] = useDeleteAttachment(
    activity,
    onClose
  );

  const handleDelete = () => {
    deleteAttachment();
  };

  return (
    <Confirm
      onClose={onClose}
      title="Delete Attachment?"
      aria-label="Delete Attachment Dialog"
      open={open}
      actions={[
        <Button
          key="delete-button"
          kind="destructive"
          onClick={handleDelete}
          loading={loading}
        >
          Delete
        </Button>,
        <Button key="cancel-button" fill="none" onClick={onClose}>
          Cancel
        </Button>,
      ]}
    >
      <div className="text-default">
        Are you sure you want to delete{" "}
        <span className="font-semibold">{fileName}</span>?
      </div>
    </Confirm>
  );
};
