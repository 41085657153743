import json5 from "json5";
import { useMemo, useState } from "react";
import {
  ArrayParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { AutoScalingGroupSortEnum } from "@m/api/public/types";
import {
  FilterBar,
  FilterSelect,
  Search,
  createFilterOptions,
  useSearchTerm,
} from "@m/ui";
import { formatFullDate } from "@m/utils";

import { Page } from "@mc/components/Page";

import {
  GET_AUTO_SCALING_GROUPS_CSV,
  getAutoScalingGroupsVariables,
  useAutoScalingGroupFilters,
  useAutoScalingGroups,
  useAutoScalingGroupsSearchTerms,
} from "../api";
import {
  ResourceDetails,
  ResourceTagsBadge,
  ResourcesPageHeader,
  ResourcesTable,
} from "../components";
import {
  ASG_LABEL,
  GET_RESOURCES_EMPTY,
  MISSION_MANAGED_FILTER_ICON,
  MISSION_MANAGED_FILTER_LABEL,
  MISSION_MANAGED_FILTER_OPTIONS,
  TABLE_HEADERS_ASG,
} from "../constants";
import { createAccountOptions } from "../utils";

export const AutoScalingGroupsPage = () => {
  const [query, setQuery] = useQueryParams({
    missionManaged: StringParam,
    accounts: ArrayParam,
    sort: withDefault(
      createEnumParam(Object.values(AutoScalingGroupSortEnum)),
      AutoScalingGroupSortEnum.CreateTimeDesc
    ),
    search: StringParam,
  });

  const {
    data: {
      filters: { accounts },
    },
  } = useAutoScalingGroupFilters();

  const {
    data: { searchTerms },
    refetch: refetchSearchTerms,
  } = useAutoScalingGroupsSearchTerms(query.search);

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ setQuery, refetchSearchTerms, query });

  /** keeping the query for the resource last simplifies testing with calls.lastQuery */
  const {
    data: { autoScalingGroups },
    loading,
    pagination,
  } = useAutoScalingGroups(query);

  const [drawerDetails, setDrawerDetails] = useState<ResourceDetails | null>(
    null
  );

  const handleSortChange = (sort: AutoScalingGroupSortEnum) => {
    pagination.setCurrentPage(1);
    setQuery({ sort });
  };

  const rows = useMemo(
    () =>
      autoScalingGroups.map((autoScalingGroup) => {
        const {
          availabilityZones = "{}",
          createTime,
          name = "",
          tags = "{}",
          tagTotal,
        } = autoScalingGroup || {};

        const az = json5.parse(availabilityZones);
        const zones = az.join(", ");
        const formattedTime = formatFullDate(createTime);

        return {
          ...autoScalingGroup,
          tags: (
            <ResourceTagsBadge
              onClick={setDrawerDetails}
              details={{
                name,
                tags: JSON.parse(tags),
              }}
              count={tagTotal}
            />
          ),
          availabilityZones: zones,
          createTime: formattedTime,
        };
      }),
    [autoScalingGroups]
  );

  const handleClearFilters = () => {
    clearSearchTerm();

    setQuery({
      missionManaged: undefined,
      accounts: undefined,
      search: undefined,
    });
  };

  const handleSelectManaged = (missionManaged: string) => {
    if (missionManaged === query.missionManaged) {
      setQuery({
        missionManaged: undefined,
      });
    } else {
      setQuery({
        missionManaged: missionManaged,
      });
    }
  };

  const handleDeselectManagedFilter = () => {
    setQuery({
      missionManaged: undefined,
    });
  };

  const handleSelectedAccountNames = (accountIds: string[]) => {
    setQuery({
      accounts: accountIds,
    });
  };

  const handleDeselectAccountsFilter = () => {
    setQuery({
      accounts: undefined,
    });
  };

  const hasActiveFilters =
    !!query.missionManaged || query.accounts?.length > 0 || !!searchTerm;

  return (
    <Page
      width="full"
      title={
        <ResourcesPageHeader
          title={ASG_LABEL}
          exportQuery={GET_AUTO_SCALING_GROUPS_CSV}
          exportVariables={getAutoScalingGroupsVariables(query)}
        />
      }
    >
      <FilterBar
        isActive={hasActiveFilters}
        onResetFilters={handleClearFilters}
        className="mb-2 p-1"
      >
        <Search
          ariaLabel="ASG Search Input"
          dropdownOptions={searchTerms}
          handleSearchInputChange={handleSearchInputChange}
          handleSubmitSearch={handleSubmitSearch}
          searchTerm={searchTerm}
        />
        <FilterSelect
          ariaLabel={MISSION_MANAGED_FILTER_LABEL}
          disabled={loading}
          icon={MISSION_MANAGED_FILTER_ICON}
          initialValue={MISSION_MANAGED_FILTER_OPTIONS.MANAGED}
          onChange={handleSelectManaged}
          onClear={handleDeselectManagedFilter}
          options={createFilterOptions(
            Object.values(MISSION_MANAGED_FILTER_OPTIONS)
          )}
          displayValue={query.missionManaged}
          selection={query.missionManaged || []}
        />
        <FilterSelect
          ariaLabel="Accounts Filter"
          disabled={accounts.length === 0 || loading}
          initialValue="Accounts"
          multiple={true}
          onChange={handleSelectedAccountNames}
          onClear={handleDeselectAccountsFilter}
          options={createAccountOptions(accounts)}
          selection={query.accounts || []}
        />
      </FilterBar>
      <ResourcesTable
        defaultSort={query.sort}
        drawerDetails={drawerDetails}
        emptyMessage={GET_RESOURCES_EMPTY(ASG_LABEL)}
        headers={TABLE_HEADERS_ASG}
        label={ASG_LABEL}
        loading={loading}
        onCloseDrawer={() => setDrawerDetails(null)}
        onSortChange={handleSortChange}
        pagination={pagination}
        rows={rows}
      />
    </Page>
  );
};
