import { useQuery } from "@apollo/client";

import { gql } from "@m/api/public";
import { RdsInstanceFilter, RdsInstanceSortEnum } from "@m/api/public/types";
import { INITIAL_PAGINATION, toast, usePaginationProps } from "@m/ui";

import { GET_RESOURCES_FAILURE, RDS_LABEL } from "../constants";

export interface RdsInstanceQueryParams {
  missionManaged: string;
  accounts: string[];
  zones: string[];
  statuses: string[];
  classes: string[];
  isMultiAz: string;
  isEncrypted: string;
  engines: string[];
  sort: RdsInstanceSortEnum;
  search: string;
}

export const RDS_INSTANCES_QUERY = gql(/* GraphQL */ `
  query RdsInstances(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchTerm: String
    $sort: [RdsInstanceSortEnum]
    $where: RDSInstanceFilter
  ) {
    rdsInstances(
      after: $after
      before: $before
      first: $first
      last: $last
      searchTerm: $searchTerm
      sort: $sort
      where: $where
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
      }
      edges {
        node {
          id
          databaseName
          instanceId
          status
          instanceClass
          availabilityZone
          isMultiAz
          isEncrypted
          accountName
          accountId
          engine
          createTime
          tagTotal
          tags
          backupPolicy
          monitoringPolicy
        }
      }
    }
  }
`);

export const RDS_INSTANCES_QUERY_CSV = gql(/* GraphQL */ `
  query RdsInstancesCsv(
    $searchTerm: String
    $sort: [RdsInstanceSortEnum]
    $where: RDSInstanceFilter
  ) {
    rdsInstances(searchTerm: $searchTerm, sort: $sort, where: $where) @csv {
      edges {
        node {
          Name: databaseName
          AccountName: accountName
          AccountId: accountId
          Status: status
          Class: instanceClass
          Zone: availabilityZone
          MultiAZ: isMultiAz
          Encrypted: isEncrypted
          Engine: engine
          Created: createTime
          Monitoring: monitoringPolicy
          Backup: backupPolicy
          Tags: tags
        }
      }
    }
  }
`);

export const useRdsInstances = (queryParams: RdsInstanceQueryParams) => {
  const onError = () => toast.error(GET_RESOURCES_FAILURE(RDS_LABEL));

  const { data, loading, error, fetchMore } = useQuery(RDS_INSTANCES_QUERY, {
    variables: getRdsInstancesVariables(queryParams),
    onError,
    notifyOnNetworkStatusChange: true,
  });

  const { edges, pageInfo, totalCount } = data?.rdsInstances || {};
  const rdsInstances = edges?.map((edge) => edge.node) || [];

  const pagination = usePaginationProps({
    pageInfo,
    totalCount,
    fetchMore,
  });

  return {
    data: { rdsInstances },
    error,
    loading,
    pagination,
  };
};

export const getRdsInstancesVariables = (
  queryParams: RdsInstanceQueryParams
) => {
  const where: RdsInstanceFilter = {};

  if (queryParams.missionManaged === "Managed") where.isMissionManaged = true;
  if (queryParams.missionManaged === "Unmanaged")
    where.isMissionManaged = false;
  if (queryParams.accounts) where.accountIn = queryParams.accounts;
  if (queryParams.zones) where.availabilityZoneIn = queryParams.zones;
  if (queryParams.statuses) where.statusIn = queryParams.statuses;
  if (queryParams.classes) where.instanceClassIn = queryParams.classes;
  if (queryParams.isMultiAz === "Multiple Zones") where.multiAz = true;
  if (queryParams.isMultiAz === "Single Zone") where.multiAz = false;
  if (queryParams.isEncrypted === "Encrypted") where.storageEncrypted = true;
  if (queryParams.isEncrypted === "Unencrypted") where.storageEncrypted = false;
  if (queryParams.engines) where.engineIn = queryParams.engines;

  return {
    sort: queryParams.sort,
    ...INITIAL_PAGINATION,
    searchTerm: queryParams.search,
    where,
  };
};
