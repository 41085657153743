import { gql } from "@m/api/public";
import { dt } from "@m/utils";

import { useExport } from "@mc/api";

export const GET_METERED_CHARGES_TRANSACTIONS_CSV = gql(/* GraphQL */ `
  query GetMarketplaceMeteredChargesCsv(
    $startDate: ArrowTypeScalar!
    $endDate: ArrowTypeScalar!
    $dimensionCode: String
    $productCode: String
  ) {
    meteredCharges(
      startDate: $startDate
      endDate: $endDate
      dimensionCode: $dimensionCode
      productCode: $productCode
    ) @csv {
      edges {
        node {
          Product: product
          PricingDimension: dimension
          UsageDate: usageDate
          Units: units
          ChargeDate: chargeDate
        }
      }
    }
  }
`);

interface ExportMeteredChargesQueryParams {
  startDate: Date;
  endDate: Date;
  dimensionCode?: string;
  productCode?: string;
}

export const useExportMeteredCharges = (
  variables: ExportMeteredChargesQueryParams
) => {
  const exportFilename = `marketplace-charges-summary--${dt
    .fromJSDate(variables?.startDate)
    .toFormat("MMMM-yyyy")}.csv`.toLowerCase();

  return useExport({
    query: GET_METERED_CHARGES_TRANSACTIONS_CSV,
    variables,
    exportFilename,
  });
};
