import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { DocumentNode } from "graphql";

import { Button } from "@m/ui";

import { useExportResources } from "../api";

interface ExportResourcesButtonProps {
  exportQuery: DocumentNode;
  exportVariables?: Record<string, any>;
  resourceName: string;
}

export const ExportResourcesButton = ({
  exportQuery,
  exportVariables = {},
  resourceName,
}: ExportResourcesButtonProps) => {
  const { mutateAsync: startExport, isPending: loading } = useExportResources({
    query: exportQuery,
    variables: exportVariables,
    resourceName,
  });

  return (
    <Button
      data-testid="export-resources-button"
      kind="primary"
      fill="solid"
      size="small"
      className="mx-2"
      leftIcon={ArrowUpTrayIcon}
      loading={loading}
      onClick={startExport}
    >
      Export
    </Button>
  );
};
