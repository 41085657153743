import { useMemo, useState } from "react";
import {
  ArrayParam,
  StringParam,
  createEnumParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { EksClusterSortEnum } from "@m/api/public/types";
import {
  FilterBar,
  FilterSelect,
  Search,
  createFilterOptions,
  useSearchTerm,
} from "@m/ui";
import { formatFullDate } from "@m/utils";

import { Page } from "@mc/components/Page";

import {
  GET_EKS_CLUSTERS_CSV,
  getEksClustersVariables,
  useEksClusterFilters,
  useEksClusterSearchTerms,
  useEksClusters,
} from "../api";
import {
  ResourceDetails,
  ResourceTagsBadge,
  ResourcesPageHeader,
  ResourcesTable,
} from "../components";
import {
  EKS_LABEL,
  GET_RESOURCES_EMPTY,
  MISSION_MANAGED_FILTER_ICON,
  MISSION_MANAGED_FILTER_LABEL,
  MISSION_MANAGED_FILTER_OPTIONS,
  TABLE_HEADERS_EKS_CLUSTERS,
} from "../constants";
import { createAccountOptions } from "../utils";

export const EksClustersPage = () => {
  const [query, setQuery] = useQueryParams({
    missionManaged: StringParam,
    accounts: ArrayParam,
    search: StringParam,
    sort: withDefault(
      createEnumParam(Object.values(EksClusterSortEnum)),
      EksClusterSortEnum.CreateTimeDesc
    ),
  });

  const {
    data: {
      filters: { accounts },
    },
  } = useEksClusterFilters();

  const {
    data: { searchTerms },
    refetch: refetchSearchTerms,
  } = useEksClusterSearchTerms(query.search);

  const {
    searchTerm,
    handleSearchInputChange,
    handleSubmitSearch,
    clearSearchTerm,
  } = useSearchTerm({ setQuery, refetchSearchTerms, query });

  /** keeping the query for the resource last simplifies testing with calls.lastQuery */
  const {
    data: { clusters },
    loading,
    pagination,
  } = useEksClusters(query);

  const [drawerDetails, setDrawerDetails] = useState<ResourceDetails | null>(
    null
  );

  const handleSelectManaged = (missionManaged: string) => {
    if (missionManaged === query.missionManaged) {
      setQuery({
        missionManaged: undefined,
      });
    } else {
      setQuery({
        missionManaged: missionManaged,
      });
    }
  };

  const handleDeselectManagedFilter = () => {
    setQuery({
      missionManaged: undefined,
    });
  };

  const handleSelectedAccountNames = (accountIds: string[]) => {
    setQuery({
      accounts: accountIds,
    });
  };

  const handleClearAccountsFilter = () => {
    setQuery({
      accounts: undefined,
    });
  };

  const handleClearFilters = () => {
    clearSearchTerm();

    setQuery({
      missionManaged: undefined,
      accounts: undefined,
      search: undefined,
    });
  };

  const handleSortChange = (sort: EksClusterSortEnum) => {
    pagination.setCurrentPage(1);

    setQuery({
      sort,
    });
  };

  const hasActiveFilters =
    !!query.missionManaged || query.accounts?.length > 0 || !!searchTerm;

  const rows = useMemo(
    () =>
      clusters.map((cluster) => {
        const tags = JSON.parse(cluster?.tags || "{}");
        const tagCount = Object.keys(tags).length;
        const name = cluster?.clusterName;

        return {
          ...cluster,
          tags: (
            <ResourceTagsBadge
              onClick={setDrawerDetails}
              details={{
                name,
                tags,
              }}
              count={tagCount}
            />
          ),
          createTime: formatFullDate(cluster?.createTime),
        };
      }),
    [clusters, setDrawerDetails]
  );

  return (
    <Page
      width="full"
      title={
        <ResourcesPageHeader
          title={EKS_LABEL}
          exportQuery={GET_EKS_CLUSTERS_CSV}
          exportVariables={getEksClustersVariables(query)}
        />
      }
    >
      <FilterBar
        isActive={hasActiveFilters}
        onResetFilters={handleClearFilters}
        className="mb-2 p-1"
      >
        <Search
          ariaLabel="EKS Cluster Search Input"
          disabled={loading}
          dropdownOptions={searchTerms}
          handleSearchInputChange={handleSearchInputChange}
          handleSubmitSearch={handleSubmitSearch}
          searchTerm={searchTerm}
        />
        <FilterSelect
          ariaLabel={MISSION_MANAGED_FILTER_LABEL}
          disabled={loading}
          icon={MISSION_MANAGED_FILTER_ICON}
          initialValue={MISSION_MANAGED_FILTER_OPTIONS.MANAGED}
          onChange={handleSelectManaged}
          onClear={handleDeselectManagedFilter}
          options={createFilterOptions(
            Object.values(MISSION_MANAGED_FILTER_OPTIONS)
          )}
          displayValue={query.missionManaged}
          selection={query.missionManaged || []}
        />
        <FilterSelect
          ariaLabel="Accounts Filter"
          disabled={accounts.length === 0 || loading}
          initialValue="Accounts"
          multiple={true}
          onChange={handleSelectedAccountNames}
          onClear={handleClearAccountsFilter}
          options={createAccountOptions(accounts)}
          selection={query.accounts || []}
        />
      </FilterBar>
      <ResourcesTable
        defaultSort={query.sort}
        drawerDetails={drawerDetails}
        emptyMessage={GET_RESOURCES_EMPTY(EKS_LABEL)}
        headers={TABLE_HEADERS_EKS_CLUSTERS}
        label="EKS Clusters"
        loading={loading}
        onCloseDrawer={() => setDrawerDetails(null)}
        onSortChange={handleSortChange}
        pagination={pagination}
        rows={rows}
      />
    </Page>
  );
};
