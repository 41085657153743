import { Suspense, useEffect, useImperativeHandle, useState } from "react";

import { EntitlementsProvider, ExtensionsProvider } from "@m/api";
import { AuthContextRef, useAuth } from "@m/login";
import { Spinner } from "@m/ui";

import { useSession } from "@mc/app/api";
import {
  CompanySessionDialog,
  DialogContext,
  DialogContextRef,
  GlobalDialog,
  MissionControlLogin,
} from "@mc/app/components";
import { CloudScoreProvider } from "@mc/features/CloudScore/providers";
import { DebugConsole } from "@mc/features/DebugConsole";
import { MarketplaceRegistrationsProvider } from "@mc/features/Onboarding";
import { useRecursiveTimeout } from "@mc/hooks";
import {
  CompanySwitcherProvider,
  IntercomProvider,
  UsersnapProvider,
} from "@mc/providers";
import { setGoogleAnalyticsVisitorType } from "@mc/utils/googleanalytics";
import { setHeapUser } from "@mc/utils/heap";
import { detectVersionChange } from "@mc/utils/reload";
import { setSentryUser } from "@mc/utils/sentry";

const VERSION_CHECK_INTERVAL = 30 * 1000;

export const AuthenticatedProviders = ({ children }) => {
  const userContext = useAuth();
  const dialogState = useState(false);

  useImperativeHandle(AuthContextRef, () => userContext);
  useImperativeHandle(DialogContextRef, () => dialogState);

  const { user } = userContext;

  // notify the user if a new version is released
  useRecursiveTimeout(detectVersionChange, VERSION_CHECK_INTERVAL);

  const { setCurrentSession } = useSession();

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setSentryUser(user);
      setHeapUser(user);
      setGoogleAnalyticsVisitorType(user);
      setCurrentSession({
        value: user.company.id,
        label: user.company.name,
      });
    }
  }, [user, setCurrentSession]);

  if (!user) return <MissionControlLogin />;

  return (
    <CompanySwitcherProvider>
      <ExtensionsProvider>
        <EntitlementsProvider>
          <MarketplaceRegistrationsProvider>
            <CloudScoreProvider>
              <IntercomProvider>
                <UsersnapProvider>
                  <DebugConsole />
                  <DialogContext.Provider value={dialogState}>
                    <GlobalDialog />
                    <Suspense fallback={<Spinner />}>
                      <CompanySessionDialog />
                      {children}
                    </Suspense>
                  </DialogContext.Provider>
                </UsersnapProvider>
              </IntercomProvider>
            </CloudScoreProvider>
          </MarketplaceRegistrationsProvider>
        </EntitlementsProvider>
      </ExtensionsProvider>
    </CompanySwitcherProvider>
  );
};
