import { AuthContextRef } from "@m/login";

import { showDialog } from "@mc/app/components/GlobalDialog";
import { MaintenanceDialog } from "@mc/features/MaintenanceDialog";

export const logout = () => {
  if (AuthContextRef?.current) AuthContextRef.current.logout(true);
};

export const onMaintenanceResponse = () => {
  showDialog(MaintenanceDialog);
};

export * from "@m/api";
